import React from 'react';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import Link from 'next/link';

const TermsAndConditions = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className='text-xsm cursor-pointer hover:underline'>Terms</div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Terms and Conditions</DialogTitle>
        </DialogHeader>
        <div className='space-y-4'>
          <p>
            1. Acceptance of Terms By accessing and using this website, you agree to be bound by these terms and
            conditions. If you do not agree with any part of these terms, please refrain from using our website.
          </p>
          <p>
            2. Services Offered This website provides information, resources, and tools for dog trainers. We do not
            guarantee the accuracy, completeness, or usefulness of any information on this website.
          </p>
          <p>
            3. User Responsibilities As a user of this website, you are responsible for maintaining the confidentiality
            of your account information and for all activities that occur under your account. You agree to provide
            accurate and current information when creating an account.
          </p>
          <p>
            4. Intellectual Property All content on this website, including text, graphics, logos, images, and software,
            is the property of Bark On Track and is protected by intellectual property laws. You may not reproduce,
            modify, distribute, or create derivative works from any content without our prior written consent.
          </p>
          <p>
            5. User-Generated Content By submitting content to this website, you grant Bark On Track a non-exclusive,
            royalty-free, perpetual, and worldwide license to use, reproduce, modify, adapt, publish, and distribute
            such content.
          </p>
          <p>
            6. Disclaimer of Warranties This website is provided on an &quot;as is&quot; and &quot;as available&quot;
            basis. We make no representations or warranties of any kind, express or implied, regarding the operation of
            this website or the information, content, materials, or products included on this website.
          </p>
          <p>
            7. Limitation of Liability In no event shall [Dog Training Website Name] be liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in connection with the use of this website
            or the information, content, materials, or products included on this website.
          </p>
          <p>
            8. External Links This website may contain links to third-party websites. We have no control over the
            content or availability of these websites and accept no responsibility for them or for any loss or damage
            that may arise from your use of them.
          </p>
          <p>
            9. Modifications to Terms and Conditions We reserve the right to modify these terms and conditions at any
            time without prior notice. Your continued use of this website after any changes constitutes your acceptance
            of the new terms and conditions.
          </p>
          <p>
            10. Governing Law These terms and conditions shall be governed by and construed in accordance with the laws
            of United States of America. Any disputes arising under or in connection with these terms shall be subject
            to the exclusive jurisdiction of the courts of United States of America.
          </p>
          <p>
            If you have any questions about these terms and conditions, please contact us at support@otter-labs.com.
          </p>
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditions;
