import React from 'react';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import Link from 'next/link';

const PrivacyPolicy = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Link className='text-xsm cursor-pointer hover:underline' href='#'>
          Privacy Policy
        </Link>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Privacy Policy</DialogTitle>
        </DialogHeader>
        <div className='space-y-4'>
          <p>
            1. Information We Collect We may collect personal information such as your name, email address, and
            demographic information when you create an account or interact with our website. We also collect
            non-personal information such as your browser type, IP address, and referring pages.
          </p>
          <p>
            2. Use of Information We use the collected information to provide and improve our services, personalize your
            experience, send promotional emails, and respond to inquiries. We may also use non-personal information for
            analytics and research purposes.
          </p>
          <p>
            3. Information Sharing We do not sell, trade, or rent your personal information to third parties. However,
            we may share your information with trusted third-party service providers who assist us in operating our
            website and conducting our business.
          </p>
          <p>
            4. Data Security We implement reasonable security measures to protect your personal information from
            unauthorized access, alteration, or disclosure. However, no method of transmission over the internet or
            electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <p>
            5. Cookies and Tracking Technologies We use cookies and similar tracking technologies to enhance your
            browsing experience, analyze trends, and collect information about your interactions with our website. You
            can choose to disable cookies in your browser settings, but this may limit certain features of our website.
          </p>
          <p>
            6. Third-Party Links Our website may contain links to third-party websites. We are not responsible for the
            privacy practices or content of those websites. We encourage you to review the privacy policies of those
            websites before providing any personal information.
          </p>
          <p>
            7. Children&apos;s Privacy Our website is not intended for children under the age of 13. We do not knowingly
            collect personal information from children. If we become aware that we have collected personal information
            from a child without parental consent, we will take steps to remove that information from our servers.
          </p>
          <p>
            8. Changes to this Privacy Policy We reserve the right to update or change this privacy policy at any time.
            Any changes will be posted on this page, and the revised version will be effective immediately upon posting.
          </p>
          <p>
            If you have any questions or concerns about our privacy policy, please contact us at support@otter-labs.com.
          </p>
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyPolicy;
